// ** Logo
import logo from "@src/assets/images/logo/logo.png";

// ** Third party components
import { useTranslation } from "react-i18next";

const SpinnerComponent = () => {
  // ** Hooks
  const { t } = useTranslation();

  return (
    <div className="fallback-spinner app-loader" id="appLoaderSpinner">
      <img
        className="fallback-logo"
        src={logo}
        alt={`${t("site_name")} logo`}
        width="250px"
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
